import axios from "axios";
import config from "../../config/config.json";
import { toast } from "react-toastify";

export const createUser = async (body) => {
  try {
    const data = await axios.post(config.API_URL + "user/create", {
      ...body,
    });

    if (data.data.success) {
      toast.success(data.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};
