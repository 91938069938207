import React, { createRef, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import QrCode from "react-qr-code";
import config from "./config/config.json";
import mahavir_block from "./assets/mahavir_block.png";
import mahavir_block_bottom from "./assets/mahavir_block_bottom.png";
import ativeer_block from "./assets/ativeer_block.png";
import ativeer_block_bottom from "./assets/ativeer_block_bottom.png";
import sanmati_block from "./assets/sanmati_block.png";
import sanmati_block_bottom from "./assets/sanmati_block_bottom.png";
import vardhman_block from "./assets/vardhman_block.png";
import vardhman_block_bottom from "./assets/vardhman_block_bottom.png";
import veer_block from "./assets/veer_block.png";
import veer_block_bottom from "./assets/veer_block_bottom.png";

function Main() {
  const { id } = useParams();

  const [data, setData] = useState({});
  const ref = createRef(null);

  useEffect(() => {
    axios.get(config.API_URL + "user/find-by-id/" + id).then((data) => {
      setData(data.data);
    });
    // eslint-disable-next-line
  }, []);

  const color =
    data.seatDetails === "Mahaveer Block F1"
      ? "#e5097f"
      : data.seatDetails === "Mahaveer Block F2"
      ? "#e5097f"
      : data.seatDetails === "Veer Block F1"
      ? "#ffff66"
      : data.seatDetails === "Veer Block F2"
      ? "#ffff66"
      : data.seatDetails === "Ativeer Block F1"
      ? "#393185"
      : data.seatDetails === "Ativeer Block F2"
      ? "#393185"
      : data.seatDetails === "Samati Block F1"
      ? "#a62b21"
      : data.seatDetails === "Samati Block F2"
      ? "#a62b21"
      : data.seatDetails === "Vardhman Block F1"
      ? "#3a3080"
      : data.seatDetails === "Vardhman Block F2"
      ? "#3a3080"
      : null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="ticket"
        style={{
          height: "68vh",
        }}
        ref={ref}
      >
        <img
          style={{
            position: "absolute",
          }}
          src={
            data.seatDetails === "Mahaveer Block F1"
              ? mahavir_block
              : data.seatDetails === "Mahaveer Block F2"
              ? mahavir_block
              : data.seatDetails === "Veer Block F1"
              ? veer_block
              : data.seatDetails === "Veer Block F2"
              ? veer_block
              : data.seatDetails === "Ativeer Block F1"
              ? ativeer_block
              : data.seatDetails === "Ativeer Block F2"
              ? ativeer_block
              : data.seatDetails === "Samati Block F1"
              ? sanmati_block
              : data.seatDetails === "Samati Block F2"
              ? sanmati_block
              : data.seatDetails === "Vardhman Block F1"
              ? vardhman_block
              : data.seatDetails === "Vardhman Block F2"
              ? vardhman_block
              : null
          }
          alt=""
          width={250}
          height={250}
        />
        <div
          style={{
            position: "relative",
            top: 410,
            background: color,
          }}
          className={`w-[250px] `}
        >
          <div className="w-full mt-[-4px]">
            <div className="p-3">
              <div>
                <h2
                  style={{
                    fontSize: 22,
                  }}
                  className="text-white main__fonts font  font-bold w-200"
                >
                  {data.fullName}
                </h2>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: -4,
                }}
              >
                <h2
                  style={{
                    fontSize: 12,
                  }}
                  className="text-white arial__font font-light  w-200"
                >
                  Block:{" "}
                  <span
                    className="text-bold"
                    style={{
                      color: "yellow",
                    }}
                  >
                    {data.seatDetails}
                  </span>
                </h2>
                <span
                  style={{
                    fontSize: 12,
                    marginTop: -3,
                  }}
                  className="text-white arial__font"
                >
                  Entry Gate :-{" "}
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Gate No.1
                  </span>
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  position: "relative",
                }}
              >
                {data.id && (
                  <QrCode
                    size={70}
                    value={data.id}
                    style={{
                      marginBottom: 4,
                    }}
                  />
                )}
              </div>
            </div>
            <img
              src={
                data.seatDetails === "Mahaveer Block F1"
                  ? mahavir_block_bottom
                  : data.seatDetails === "Mahaveer Block F2"
                  ? mahavir_block_bottom
                  : data.seatDetails === "Veer Block F1"
                  ? veer_block_bottom
                  : data.seatDetails === "Veer Block F2"
                  ? veer_block_bottom
                  : data.seatDetails === "Ativeer Block F1"
                  ? ativeer_block_bottom
                  : data.seatDetails === "Ativeer Block F2"
                  ? ativeer_block_bottom
                  : data.seatDetails === "Samati Block F1"
                  ? sanmati_block_bottom
                  : data.seatDetails === "Samati Block F2"
                  ? sanmati_block_bottom
                  : data.seatDetails === "Vardhman Block F1"
                  ? vardhman_block_bottom
                  : data.seatDetails === "Vardhman Block F2"
                  ? vardhman_block_bottom
                  : null
              }
              style={{
                marginTop: -59,
              }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
