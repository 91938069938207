import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  approveUser,
  assignBlock,
  deleteUser,
  excelUpload,
  getUsers,
} from "./apis";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import excel from "../../assets/excel.png";
import axios from "axios";
import config from "../../config/config.json";
import { IconButton, Tooltip } from "@mui/material";

function TableComponent() {
  const [users, setUsers] = useState([]);
  const inputRef = useRef(null);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle] = useState(false);
  const [selected, setSelected] = useState({});
  const [clearRows, setClearRows] = useState(false);

  const navigate = useNavigate();

  const data = users?.map((user) => ({
    ...user,
  }));

  const filteredItems = data?.filter(
    (item) =>
      item && item.fullName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    if (selected.selectedCount > 0) {
      return (
        <button
          onClick={() => {
            const ids = selected.selectedRows.map((data) => data.id);
            approveUser(ids, setUsers, selected.selectedRows);
            setClearRows(!clearRows);
          }}
          type="button"
          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Approve Users
        </button>
      );
    }
    return (
      <div className="flex items-center">
        <input
          style={{
            width: 250,
          }}
          value={filterText}
          class="block w-full p-2.5  w-[140px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(e) => setFilterText(e.target.value)}
          placeholder="Search"
        />

        <CSVLink filename="users" data={users}>
          <img src={excel} alt="" />
        </CSVLink>
      </div>
    );

    // eslint-disable-next-line
  }, [filterText, resetPaginationToggle, selected, data, clearRows]);

  const getUsersApi = async () => {
    const data = await getUsers();

    if (data?.success) {
      setUsers(data.users);
      toast.success("Users Fetched Successfully!");
    } else {
      toast.error(data?.message);
    }
  };

  useEffect(() => {
    getUsersApi();
    // eslint-disable-next-line
  }, []);

  const blocks = [
    "Veer Block F1",
    "Veer Block F2",
    "Ativeer Block F1",
    "Ativeer Block F2",
    "Samati Block F1",
    "Samati Block F2",
    "Vardhman Block F1",
    "Vardhman Block F2",
    "Mahaveer Block F1",
    "Mahaveer Block F2",
    "VVIP L1",
    "VVIP L1A",
    "VIP L2",
    "VIP L3",
  ];

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const contextActions = React.useMemo(() => {
    return <button>Delete</button>;
    // eslint-disable-next-line
  }, [data]);

  return (
    <div class="mx-auto mt-10 max-w-14xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Users
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your DB.
              </p>
            </div>

            <input
              type="file"
              ref={inputRef}
              accept=".xlsx"
              style={{
                display: "none",
              }}
              onChange={(e) => {
                excelUpload(e, setUsers);
              }}
            />
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                onClick={() => {
                  inputRef.current.click();
                }}
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add user
              </button>
            </div>
          </div>

          <div className="shadow-2xl mt-2 bt-2">
            <DataTable
              pagination
              selectableRows
              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              onSelectedRowsChange={(e) => {
                setSelected(e);
              }}
              contextActions={contextActions}
              sortFunction={customSort}
              columns={[
                {
                  name: "Sr No",
                  selector: (row, index) => index + 1,
                  center: true,
                },
                {
                  name: "Name",
                  selector: (row, index) => row.fullName,
                  center: true,
                },
                {
                  name: "Phone",
                  selector: (row, index) => row.phoneNumber,
                  center: true,
                },
                {
                  name: "Email",
                  selector: (row, index) => row.email,
                  center: true,
                },

                {
                  name: "Address",
                  selector: (row, index) =>
                    `${row.homeAddress} ${row.state}, ${row.city} , ${row.pinCode}`,
                  center: true,
                },

                {
                  name: "Block",
                  cell: (row, index) => (
                    <>
                      {row.approved ? (
                        <select
                          disabled
                          id="location"
                          name="location"
                          defaultValue={row.seatDetails}
                          onChange={(e) => {
                            const data = {
                              seatDetails: e.target.value,
                              id: row.id,
                            };
                            assignBlock(data);
                          }}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          {/* <option value="">Auto</option> */}
                          {blocks.map((data) => {
                            return <option value={data}>{data}</option>;
                          })}
                        </select>
                      ) : (
                        <select
                          id="location"
                          name="location"
                          defaultValue={row.seatDetails}
                          onChange={(e) => {
                            const data = {
                              seatDetails: e.target.value,
                              id: row.id,
                            };
                            assignBlock(data);
                          }}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          {/* <option value="">Auto</option> */}
                          {blocks.map((data) => {
                            return <option value={data}>{data}</option>;
                          })}
                        </select>
                      )}
                    </>
                  ),
                  center: true,
                },
                {
                  name: "Action",
                  cell: (row, index) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {row.approved ? (
                          <Tooltip
                            style={{
                              width: 33,
                              height: 33,
                            }}
                            title="Resend Ticket"
                          >
                            <IconButton
                              onClick={async () => {
                                await axios
                                  .post(config.API_URL + "user/ticket/send", {
                                    ...row,
                                  })
                                  .then(({ data }) => {
                                    if (data.success) {
                                      toast.success("Ticket Resent.");
                                    }
                                  })
                                  .catch((err) => {
                                    toast.error(err.message);
                                  });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            style={{
                              width: 33,
                              height: 33,
                            }}
                            title="Approve"
                          >
                            <IconButton
                              onClick={() =>
                                approveUser(
                                  row.id,
                                  setUsers,
                                  selected.selectedCount > 0
                                    ? selected.selectedRows
                                    : [row]
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title="Edit">
                          <IconButton
                            style={{
                              width: 33,
                              height: 33,
                            }}
                            onClick={() => navigate(`/user/${row.id}/edit`)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                              />
                            </svg>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            style={{
                              width: 33,
                              height: 33,
                            }}
                            onClick={() => {
                              deleteUser(row, setUsers);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                  center: true,
                },
              ]}
              data={filteredItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableComponent;
