import { useFormik } from "formik";
import formValidation from "./validation";
import { createUser } from "./apis";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import config from "../../config/config.json";
import { updateUser } from "../Table/apis";
import { Grid } from "@mui/material";
export default function Form() {
  const { id } = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      fullName: "",
      aadharNumber: "",
      homeAddress: "",
      country: "",
      state: "",
      pinCode: "",
      city: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: formValidation,
    onSubmit: (values, actions) => {
      const submitVals = {
        ...values,
        phoneNumber: `${values.phoneNumber}`,
        aadharNumber: `${values.aadharNumber}`,
        pinCode: `${values.pinCode}`,
        id: id ? id : null,
      };

      if (id) {
        updateUser(submitVals, navigate);
      } else {
        createUser(submitVals);
      }

      actions.resetForm();
    },
  });

  useEffect(() => {
    if (id) {
      axios.get(config.API_URL + `user/find-by-id/${id}`).then(({ data }) => {
        formik.setValues({
          fullName: data.fullName,
          aadharNumber: data.aadharNumber,
          homeAddress: data.homeAddress,
          email: data.email,
          phoneNumber: data.phoneNumber,
          pinCode: data.pinCode,
        });
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const { errors, touched } = formik;

  return (
    <div class="mx-auto mt-10 max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-5xl">
        <Grid
          container
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          {/* eslint-disable */}
          <Grid item xs={12} md={4} sm={12}>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Full Name
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                name="fullName"
                id="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                className={[
                  "block p-2.5  outline-0  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6",
                ]}
              />

              {errors.fullName && touched.fullName && (
                <p class="mt-2 text-sm text-red-600 text-red-500">
                  {errors.fullName}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Aadhar Number
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                name="aadharNumber"
                id="aadharNumber"
                value={formik.values.aadharNumber}
                onChange={formik.handleChange}
                className={[
                  "block p-2.5 outline-0  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6",
                  errors.aadharNumber &&
                    touched.aadharNumber &&
                    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 text-red-500 placeholder-red-500 border-red-500",
                ]}
              />

              {errors.aadharNumber && touched.aadharNumber && (
                <p class="mt-2 text-sm text-red-600 text-red-500">
                  {errors.aadharNumber}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Phone Number
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                name="phoneNumber"
                id="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                className={[
                  "block p-2.5  outline-0  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6",
                ]}
              />

              {errors.phoneNumber && touched.phoneNumber && (
                <p class="mt-2 text-sm text-red-600 text-red-500">
                  {errors.phoneNumber}
                </p>
              )}
            </div>
          </Grid>
          <Grid xs={12} md={4} sm={12}>
            <label
              htmlFor="email"
              className="block text-sm  font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Email Address
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                type="email"
                name="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={[
                  "block p-2.5 outline-0  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6",
                  errors.email &&
                    touched.email &&
                    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 text-red-500 placeholder-red-500 border-red-500",
                ]}
              />

              {errors.email && touched.email && (
                <p class="mt-2 text-sm text-red-600 text-red-500">
                  {errors.email}
                </p>
              )}
            </div>
          </Grid>

          <Grid xs={12} md={4} sm={12}>
            <label
              htmlFor="postal-code"
              className="block text-sm  font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              ZIP / Postal code
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
              <input
                type="number"
                name="pinCode"
                value={formik.values.pinCode}
                id="pinCode"
                onChange={(e) => {
                  formik.setFieldValue("pinCode", e.target.value);

                  if (e.target.value.length === 6) {
                    toast.promise(
                      axios
                        .get(
                          `https://api.postalpincode.in/pincode/${e.target.value}`
                        )
                        .then(({ data }) => {
                          formik.setFieldValue(
                            "country",
                            data[0]?.PostOffice[0].Country
                          );
                          formik.setFieldValue(
                            "state",
                            data[0]?.PostOffice[0].State
                          );
                          formik.setFieldValue(
                            "city",
                            data[0]?.PostOffice[0].Name
                          );
                        })
                        .catch((err) => {
                          console.log(err);
                        }),
                      {
                        pending: "Pincode is being Fetched..",
                        success: "Pincode Fetched 👌",
                        error: "Something went wrong 🤯",
                      }
                    );
                  }
                }}
                className={[
                  "block p-2.5 outline-0  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6",
                  errors.pinCode &&
                    touched.pinCode &&
                    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 text-red-500 placeholder-red-500 border-red-500",
                ]}
              />

              {errors.pinCode && touched.pinCode && (
                <p class="mt-2 text-sm text-red-600 text-red-500">
                  {errors.pinCode}
                </p>
              )}
            </div>
          </Grid>

          <Grid xs={12}>
            <label
              htmlFor="street-address"
              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
            >
              Full address
            </label>
            <div className="mt-2">
              <textarea
                id="homeAddress"
                name="homeAddress"
                rows={3}
                value={formik.values.homeAddress}
                className={[
                  "block w-full p-2.5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                  errors.homeAddress &&
                    touched.homeAddress &&
                    "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 text-red-500 placeholder-red-500 border-red-500",
                ]}
                defaultValue={formik.values.homeAddress}
                onChange={formik.handleChange}
              />
              {errors.homeAddress && touched.homeAddress && (
                <p class="mt-2 text-sm text-red-600 text-red-500">
                  {errors.homeAddress}
                </p>
              )}
            </div>
          </Grid>

          <div className="mt-6 p-1 flex w-full items-center justify-center gap-x-6">
            <button
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
              className="inline-flex w-full  justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button>
          </div>
        </Grid>
      </div>
    </div>
  );
}
