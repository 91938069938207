import axios from "axios";
import config from "../../config/config.json";
import { toast } from "react-toastify";
export const getUsers = async () => {
  try {
    const { data } = await axios.get(config.API_URL + "user/get-all");
    return data;
  } catch (error) {
    return error;
  }
};

export const approveUser = async (id, setState, selectedRows) => {
  await axios
    .patch(config.API_URL + "user/approve", {
      ids: [id],
      users: selectedRows,
    })

    .then((data) => {
      if (data.data.success === true) {
        toast.success(data.data.message);
        setState(data.data.user);

        if (selectedRows.length > 1) {
          selectedRows.map((data) => {
            if (data.approved === true) {
            } else {
              axios
                .post(config.API_URL + "user/ticket/send", {
                  ...data,
                })
                .then((data) => {
                  toast.success("Ticket Sent.");
                });
            }
          });
        } else {
          if (selectedRows[0].approved) {
            console.log(selectedRows);
          } else {
            axios
              .post(config.API_URL + "user/ticket/send", {
                ...selectedRows[0],
              })
              .then((data) => {
                toast.success("Ticket Sent.");
              });
          }
        }
      }
    })
    .catch((err) => {
      toast.error(err.message);
    });
};

export const excelUpload = (e, setState) => {
  const formData = new FormData();

  formData.append("csvFile", e.target.files[0]);

  axios
    .post(config.API_URL + "user/bulk-post", formData)
    .then((data) => {
      console.log(data);
      if (data.data.success) {
        toast.success(data.data.message);
        // setState(data.data.user);
        window.location.reload();
      }
    })
    .catch((err) => {
      toast.error(err.message);
    });
};

export const assignBlock = async (body) => {
  await axios
    .patch(`${config.API_URL}user/update/${body.id}`, {
      ...body,
    })
    .then((data) => {
      if (data.data.success === true) {
        toast.success("Block Successfully Assigned!");
      }
    })
    .catch((err) => {
      toast.success(err.message);
    });
};

export const updateUser = async (body, navigate) => {
  await axios
    .patch(`${config.API_URL}user/update/${body.id}`, {
      ...body,
    })
    .then((data) => {
      if (data.data.success === true) {
        toast.success("User Updated");
        navigate("/users");
      }
    })
    .catch((err) => {
      toast.success(err.message);
    });
};

export const deleteUser = async (body, setState) => {
  await axios
    .delete(`${config.API_URL}user/delete/${body.id}`)
    .then(({ data }) => {
      if (data.success) {
        toast.success("User Deleted Successfully");
        setState(data.user);
      }
    })
    .catch((err) => {
      toast.error(err.message);
    });
};
