import { Button, Checkbox, Container, Paper, Stack } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import * as xlsx from "xlsx";
function Whatsapp() {
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [createGroup, setCreateGroup] = useState(false);

  const getGroups = async () => {
    await axios
      .get("https://api.2550mahavirnirvanotsav.com/user/get-groups")
      .then((data) => {
        setGroups(data.data.groups);
      });
  };
  useEffect(() => {
    getGroups();
    // const group = localStorage.getItem("Group");
    // setGroups(JSON.parse(group));
  }, []);

  const _groups = groups.filter(
    (data) => data.groupMetadata.owner.user === "919540460108"
  );

  console.log(_groups);

  // for (let index = 0; index < element.length; index++) {
  //   const doc = element[index];

  //   for (let index = 0; index < doc.participants.length; index++) {
  //     const elements = doc.participants[index];
  //     if (
  //       elements.id === "919582576108@s.whatsapp.net" &&
  //       elements.admin === "superadmin"
  //     ) {
  //       temp.push({
  //         ...doc,
  //       });
  //     }
  //   }
  // }

  const handleUpload = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = xlsx.read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = xlsx.utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setExcelData(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const addUsersToGroup = async () => {
    const data = selected.map((data) => ({
      id: `91${data.phoneNumber}@c.us`,
    }));

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    for (let index = 0; index < 2; index++) {
      sleep(2000);
      await axios
        .post(
          createGroup
            ? "https://api.2550mahavirnirvanotsav.com/user/create-whatsapp-group"
            : "https://api.2550mahavirnirvanotsav.com/user/add-to-whatsapp",
          {
            groupId: group,
            participants: [{ id: data[index].id }],
          }
        )
        .then((data) => {
          if (data.data.success > 0) {
            // console.log("Success!", data[index].id);
            setSelected([]);
            setExcelData([]);
          }
        })
        .catch((err) => {
          // alert(JSON.stringify(err));
        });
      sleep(2000);
    }
  };
  return (
    <div>
      <Container>
        <Stack spacing={2}>
          {createGroup ? (
            <div>
              <label
                for="Groups"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Groups
              </label>
              <input
                type="text"
                name="fullName"
                id="fullName"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                className={[
                  "block p-2.5  outline-0  w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6",
                ]}
              />
              <label htmlFor="CreateGroup">Create Group</label>
              <Checkbox
                checked={createGroup}
                onChange={(e) => setCreateGroup(!createGroup)}
              />
            </div>
          ) : (
            <div>
              <label
                for="Groups"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Groups
              </label>
              <select
                value={group}
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Please Select Group</option>

                {_groups?.map((data) => (
                  <option value={data.groupMetadata.id._serialized}>
                    {data.name}
                  </option>
                ))}
              </select>
              <label htmlFor="CreateGroup">Create Group</label>
              <Checkbox
                checked={createGroup}
                onChange={(e) => setCreateGroup(e)}
              />
            </div>
          )}

          <div>
            <input type="file" onChange={handleUpload} />
          </div>

          {excelData.length > 0 && (
            <Paper>
              <DataTable
                data={excelData}
                selectableRows
                onSelectedRowsChange={(e) => {
                  setSelected(e.selectedRows);
                }}
                columns={[
                  {
                    name: "Sr No.",
                    selector: (row, index) => index + 1,
                  },
                  {
                    name: "Name",
                    selector: (row, index) => row.name,
                  },
                  {
                    name: "Phone Number",
                    selector: (row, index) => row.phoneNumber,
                  },
                ]}
              />
            </Paper>
          )}

          {selected.length > 0 && (
            <Button onClick={addUsersToGroup} fullWidth variant="contained">
              Add to Group
            </Button>
          )}
        </Stack>
      </Container>
    </div>
  );
}

export default Whatsapp;
