import * as yup from "yup";

const formValidation = yup.object({
  fullName: yup.string().required("Full Name is required."),
  email: yup.string().email("Enter Valid Email").required("Email is required"),
  phoneNumber: yup
    .number()
    .min(10, "Phone Number must be of 10 characters")
    .required("This field is requried"),
  aadharNumber: yup.string().required("Aadhar Number is Required."),
  homeAddress: yup.string().required("Address is Required."),
  country: yup.string(),
  state: yup.string(),
  city: yup.string(),
  pinCode: yup.number().required("Pin Code is required"),
});

export default formValidation;
