import React, { useRef } from "react";
import Header from "./components/Header";
import Form from "./components/Form";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TableComponent from "./components/Table";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Main from "./main";
import "./App.css";
import Whatsapp from "./Whatsapp";

const App = () => {
  let container = useRef(null);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Form />
              </>
            }
          />
          <Route
            path="/user/:id/edit"
            element={
              <>
                <Form />
              </>
            }
          />
          <Route
            element={
              <>
                <TableComponent toaster={container} />
              </>
            }
            path="/users"
          />

          <Route
            element={
              <>
                <Header />
                <Main />
              </>
            }
            path="/user/:id"
          />

          <Route
            element={
              <>
                <Header />
                <Whatsapp />
              </>
            }
            path="/whatsapp"
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
